import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class ServicioService {
  async getServiciosAll() {
    const servicios = await fetchWrapper.get(`${ruta}/servicio`);
    return servicios;
  }

  async saveServicio(servicio) {
    const newService = await fetchWrapper.post(`${ruta}/servicio`, servicio);
    return newService;
  }
  async updatedServicio(servicio) {
    const updateService = await fetchWrapper.put(
      `${ruta}/servicio/` + servicio.id,
      servicio
    );
    return updateService;
  }

  async eliminarServicio(id) {
    return await fetchWrapper.delete(`${ruta}/servicio/${id}`);
  }
}
