<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '450px' }"
    header="Servicio Detalle"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <label for="descripcion"
            ><strong>NOMBRE DE SERVICIO: </strong>
            <span class="p-invalid">*</span></label
          >
          <InputText
            id="descripcion"
            v-model.trim="data_servicio.descripcion"
            required="true"
            autofocus
            @keyup.enter="saveServicio"
          />
          <small class="p-invalid" v-if="errors.descripcion">{{
            errors.descripcion[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-12">
          <label for="estado"
            ><strong>ESTADO: </strong><span class="p-invalid">*</span></label
          >
          <Dropdown
            id="estado"
            v-model="data_servicio.estado"
            :disabled="!data_servicio.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado..."
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
        :disabled="!enviado ? false : true"
      />
      <Button
        :label="data_servicio.id ? 'ACTUALIZAR' : 'GUARDAR'"
        icon="pi pi-check"
        class="p-button-primary p-button-lg"
        @click="saveServicio"
        :disabled="!enviado ? false : true"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>
  
  <script>
import ServicioService from "@/service/ServicioService";
export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    servicio: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  ServicioService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_servicio: this.servicio,
      enviado: false,
      estados: [
        { label: "INACTIVO", value: 0 },
        { label: "ACTIVO", value: 1 },
      ],
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
    };
  },
  created() {
    this.ServicioService = new ServicioService();
  },
  methods: {
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
    },
    saveServicio() {
      this.errors = {};
      this.enviado = true;
      if (this.servicio.id) {
        let servicio_enviar = {
          ...this.data_servicio,
          estado: this.data_servicio.estado.value,
        };
        this.ServicioService.updatedServicio(servicio_enviar).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListado");
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      } else {
        let tmp = this.data_servicio;
        this.data_servicio = {
          ...this.data_servicio,
          estado: this.data_servicio.estado.value,
        };
        this.ServicioService.saveServicio(this.data_servicio).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
            this.data_servicio = tmp;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 10000,
            });
            this.$emit("actualizarListado", data.servicio);
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    servicio(val) {
      this.data_servicio = val;
    },
  },
};
</script>
  <style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>